import  {useState} from 'react'
import '../CSS/Inquire.css'
import emailjs from '@emailjs/browser';
import { NavLink } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface EmailJSResponse {
  status: number;
  text: string;
}

function Inquire() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');

const submitInquiry = (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();

  const templateParams = {
    first_name: firstName,
    last_name: lastName,
    message: message,
    email: email
  }

    emailjs.send('service_2yngh1n','template_4lgmdrq', templateParams, 'D_LcMzXjh7P-AdaIB')
    .then((res: EmailJSResponse) => {
      toast.info('Email sent successfully!');
      setFirstName('');
      setLastName('');
      setEmail('');
      setMessage('');
    })
    .catch((err: any) => { 
      toast.error(`Error sending email, ${err}`);
    });
}


  return (
    <div className='inquireWrapper'>
      <ToastContainer />
      <div className='inquireMessageContainer'>
        <h1>For inquiries or custom orders, please contact me by filling out the form!</h1>
      </div>
      <div className='inquireFormContainer'>
        <form className='inquireForm' onSubmit={submitInquiry}>
          <label htmlFor='firstName'>First Name:</label>
          <input 
          type='text'
          name='firstName'
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          placeholder='John'
          required />
          <label htmlFor='lasttName'>Last Name:</label>
          <input 
          type='text'
          name='lastName'
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          placeholder='Doe'
          required />
          <label htmlFor='email'>Email:</label>
          <input
          type='email'
          value={email}
          name='email'
          onChange={(e) => setEmail(e.target.value)}
          placeholder='johndoe@gmail.com'
          required
          />
          <label htmlFor='message'>Message:</label>
          <textarea
          name='message'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required />
          <input 
          type='submit' 
          value='Submit' />
        </form>
      </div>
      <div className='links'>
        <NavLink className='optionLinks' to='/About'>About Me</NavLink>
        <NavLink className='optionLinks' to='/Items'>My Items</NavLink>
      </div>
    </div>
  )
}

export default Inquire
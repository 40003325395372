import '../CSS/App.css';
import { Routes, Route } from 'react-router';
import Home from '././Home';
import Items from '././Items';
import Inquire from '././Inquire';
import { NavBar } from '././NavBar';
import { Footer } from './Footer';
import { About } from './About';
import { Crocheting } from './Crocheting';
import { Portraits } from './Portraits';
import { Embroidery } from './Embroidery';
import { Knitting } from './Knitting';

export const App = () => {

  function NotFound() {
    return <div>
      <h1>404: Page Not Found</h1>
      <h2>Sorry, the page you are looking for does not exist</h2>
      <h3>Click on the links above to navigate to a different page</h3>
      </div>
}
  return (
    <div className="App">
      <NavBar />
      <header className="App-header">
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/About' element={<About />} />
          <Route path='/Items' element={<Items />} />
          <Route path='/Inquire' element={<Inquire />} />
          {/* <Route path='/crocheting' element={<Crocheting />} />
          <Route path='/portraits' element={<Portraits />} />
          <Route path='/embroidery' element={<Embroidery />} />
          <Route path='/knitting' element={<Knitting />} /> */}
          <Route path='*' element={<NotFound />} />
        </Routes>
      </header>
      <Footer />
    </div>
  );
}

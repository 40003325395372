import React from 'react'
import '../CSS/Home.css'
import { Link } from 'react-router-dom'

function Home() {
  return (
    <div className='homeWrapper'>
      <div className='homeContainer'>
        <h1>Welcome to HBH</h1>
        <p>🧶🪡Crafted items made by hand and love 🫶🏻</p>
        <div className='homeButtonContainer'>
          <Link className='homeButton' to='/Items'>Items</Link>
          <Link className='homeButton' to='/About'>About</Link>
        </div>
        </div>
    </div>
  )
}

export default Home
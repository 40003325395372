import { useState } from 'react'
import {NavLink} from 'react-router-dom'
import '../CSS/NavBar.css'
import bar from '../Images/dropDownBar.png'
import x from '../Images/x.png'

export const NavBar = () => {
  const [navOpen, setNavOpen] = useState(false)

  const handleNav = () => {
    return (
    <div className='dropDown'>
      <NavLink className="links" to='/' onClick={() => setNavOpen(!navOpen)}>Home</NavLink>
      <NavLink className="links" to='/About' onClick={() => setNavOpen(!navOpen)}>About</NavLink>
      <NavLink className="links" to='/Items' onClick={() => setNavOpen(!navOpen)}>Items</NavLink>
      <NavLink className="links" to='/Inquire' onClick={() => setNavOpen(!navOpen)}>Inquire</NavLink>
    </div>
      
    )
  }
  return (
    <div className='navWrapper'>
      <div className='navContainer'>
        <NavLink className='link' to='/'>
          <img className='yarn'src={`${process.env.PUBLIC_URL}/yarnSpool.png`} alt='logo' />
        </NavLink>
        <div className='h1Wrapper'>
          <h1>HBH</h1>
        </div>
        <div className='navBar'>
          <button className='dropDownButton toggle-off' onClick={() => setNavOpen(!navOpen)}><img alt='open menu' src={navOpen ? x : bar}/></button>
        </div>
      </div>
        <div className='dropDownContainer'>
        {navOpen && handleNav()}
        </div>
    </div>
  )
}

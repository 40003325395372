import React, {useEffect, useState} from 'react'
import '../CSS/Embroidery.css'
import { EmbroideryImage } from '../Types/Types'; 
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import manifest from '../manifest.json';
import { NavLink } from 'react-router-dom';

interface EmbroideryProps {
  onClose: () => void;
}

export const Embroidery: React.FC<EmbroideryProps> = ({ onClose }) => {
  const [embroidery, setEmbroidery] = useState<EmbroideryImage[]>([]);

  useEffect(() => {
    setEmbroidery(manifest.embroideryImages);
  }, [])
  return (
      <>
      <div className='embroideryModal'>
        <div className='embroideryContainer'>
      <div className='closeButtonContainer'>
      <button className='closeButton' onClick={onClose}>Close</button>
      </div>
      <h1>Custom Embroidered Items!</h1>
      <Carousel showThumbs={false} infiniteLoop={true} autoPlay={true} interval={3000}>
      {embroidery.map((embroideryImage: EmbroideryImage, index: number) => (
          <div key={index} className='embroideryImageContainer'>
          <img src={embroideryImage.src} alt={`Embroidery ${index + 1}`} />
          </div>
        ))}
      </Carousel>
      <div className='links'>
        <NavLink className='optionLinks' to='/Inquire'>Inquire</NavLink>
      </div>
      </div>
      </div>
    </>
    );
}

import {useState, useEffect} from 'react'
import manifest from '../manifest.json';
import { KnittedImage } from '../Types/Types';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { NavLink } from 'react-router-dom';
import '../CSS/Knitting.css'

interface KnittingProps {
  onClose: () => void;
}

export const Knitting: React.FC<KnittingProps> = ({ onClose }) => {
    const [knitting, setKnitting] = useState<KnittedImage[]>([]);
    useEffect(() => {
        setKnitting(manifest.knittedImages);
    },[])
  return (
    <>
    <div className='knittingModal'>
        <div className='knittedContainer'>
      <div className='closeButtonContainer'>
      <button className='closeButton' onClick={onClose}>Close</button>
      </div>
      <h1>Custom Knitted Items!</h1>
          <Carousel showThumbs={false} infiniteLoop={true} autoPlay={true} interval={3000}>
            {knitting.map((knittedImages: KnittedImage, index: number) => (
                  <div key={index} className='knittedImageContainer'>
                <img src={knittedImages.src} alt={`Embroidery ${index}`} />
                </div>
            ))}
          </Carousel>
      <div className='links'>
        <NavLink className='optionLinks' to='/Inquire'>Inquire</NavLink>
      </div>
      </div>
      </div>
    </>
  )
}

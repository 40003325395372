import {useState, useEffect} from 'react'
import manifest from '../manifest.json';
import { CrotchedImage } from '../Types/Types';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { NavLink } from 'react-router-dom'
import '../CSS/Crocheting.css'

interface CrochetingProps {
  onClose: () => void;
}

export const Crocheting: React.FC<CrochetingProps> = ({ onClose }) => {
    const [crochetes, setCrocheting] = useState<CrotchedImage[]>([]);
    useEffect(() => {
        setCrocheting(manifest.crotchedImages);
    },[])
  return (
    <>
    <div className='crochetingModal'>
        <div className='crochetedContainer'>
    <div className='closeButtonContainer'>
      <button className='closeButton' onClick={onClose}>Close</button>
      </div>
      <h1>Custom Crocheted Items!</h1>
          <Carousel showThumbs={false} infiniteLoop={true} autoPlay={true} interval={3000}>
      {crochetes.map((crotchedImages: CrotchedImage, index: number) => (
            <div key={index} className='crochetedImageContainer'>
          <img src={crotchedImages.src} alt={`Embroidery ${index}`} />
          </div>
      ))}
          </Carousel>
      <div className='links'>
        <NavLink className='optionLinks' to='/Inquire'>Inquire</NavLink>
        </div>
        </div>
      </div>
    </>
  )
}
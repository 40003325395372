import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Portraits } from './Portraits'
import { Knitting } from './Knitting'
import { Embroidery } from './Embroidery'
import { Crocheting } from './Crocheting'
import '../CSS/Items.css'

type ModalType = string | null;

function Items() {
  const [activeModal, setActiveModal] = useState<ModalType | null>(null)


  const handleModalToggle = (modalType: ModalType) => {
    setActiveModal(activeModal === modalType ? null : modalType)
  }

  const renderModal = () => {
    switch (activeModal) {
      case 'portraits':
        return <Portraits onClose={() => setActiveModal(null)} />
      case 'crocheting':
        return <Crocheting onClose={() => setActiveModal(null)} />
      case 'knitting':
        return <Knitting onClose={() => setActiveModal(null)} />
      case 'embroidery':
        return <Embroidery onClose={() => setActiveModal(null)} />
      default:
        return null
    }
  }

  return (
    <div className='itemsWrapper'>
      <h1 className='headerMessage'>Items I make and sell! Please click on the individual photo to see more!</h1>
      <div className='itemsContainer'>
        <div className='portraits' onClick={() => handleModalToggle('portraits')}>
          <img className='item' src={`${process.env.PUBLIC_URL}/weddingkiss.JPG`} alt='Wedding Kiss Portrait' />
          <p className='itemDescription'>Digital Portraits</p>
        </div>
        <div className='crocheting' onClick={() => handleModalToggle('crocheting')}>
          <p className='itemDescription'>Crocheted Items</p>
          <img className='item' src={`${process.env.PUBLIC_URL}/pumpkinHat.jpg`} alt='Pumpkin Hat Crochet' />
        </div>
        <div className='knitting' onClick={() => handleModalToggle('knitting')}>
          <img className='item' src={`${process.env.PUBLIC_URL}/knittedCranberryHats.JPG`} alt='Knitted Cranberry Hats' />
          <p className='itemDescription'>Knitted Items</p>
        </div>
        <div className='embroidery' onClick={() => handleModalToggle('embroidery')}>
          <p className='itemDescription'>Embroidered Items</p>
          <img className='item' src={`${process.env.PUBLIC_URL}/elijahEmbroiderdSweater.JPG`} alt='Elijah Embroidered Sweater' />
        </div>
      </div>
      {activeModal && (
        <div className='modalOverlay'>
          {renderModal()}
        </div>
      )}
    </div>
  )
}

export default Items
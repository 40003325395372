import React, { useState, useEffect } from 'react'
import '../CSS/Portraits.css'
import manifest from '../manifest.json';
import { DigitalPortrait } from '../Types/Types';
import { NavLink } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

interface PortraitsProps {
  onClose: () => void;
}

export const Portraits: React.FC<PortraitsProps> = ({ onClose }) => {
    const [portraits, setPortraits] = useState<DigitalPortrait[]>([]);

    useEffect(() => {
        setPortraits(manifest.digitalPortraits);
    }, [])

    return (
        <div className='portraitsModal'>
            <div className='portraitsContainer'>
          <div className='closeButtonContainer'>
      <button className='closeButton' onClick={onClose}>Close</button>
      </div>
                <h1>Custom Digital Portraits</h1>
                <Carousel showThumbs={false} infiniteLoop={true} autoPlay={true} interval={3000}>
                    {portraits.map((digitalPortrait: DigitalPortrait, index: number) => (
                      <div key={index} className='portraitImageContainer'>
                            <img src={digitalPortrait.src} alt={`Digital Portrait ${index + 1}`} />
                        </div>
                    ))}
                </Carousel>
                <div className='links'>
                    <NavLink className='optionLinks' to='/Inquire'>Inquire</NavLink>
                </div>
            </div>
        </div>
    )
}

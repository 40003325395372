import { NavLink } from 'react-router-dom'
import '../CSS/About.css'
export const About = () => {
  return (
    <div className='aboutWrapper'>
            <h1>About Me!</h1>
        <div className='aboutContainer'>
            <div className='aboutTextContainer'>
                <p className='aboutMeMessage'>Hello, my name is Haylee! I have a passion for crafting and creating custom items. There's nothing quite like witnessing the joy and excitement on people's faces when they receive their specially made order. My journey began in junior high school, where I picked up crocheting and quickly taught myself to craft a wide range of items. Since then, I've expanded my skills to include knitting, embroidery, and digital portraiture, continually exploring new techniques and mediums to bring my creative visions to life 🩷 </p>
            </div>
            <div className='aboutImageContainer'>
                <img className='aboutImage' src={`${process.env.PUBLIC_URL}hayleeaboutimage.JPG`} alt='personal'/>
            </div>
        </div>
            <h2 className='socialsMessage'>Please checkout and follow my socials for updates!</h2>
            <div className='socialsContainer'>
                <a href='https://www.instagram.com/handmade.byhaylee?igsh=NGh0Y3M0aGRmbWh0' target='_blank' rel='noreferrer'>
                <img className='socials' src={`${process.env.PUBLIC_URL}/icons8-instagram-100.png`} alt='instagram'/>
                </a>
                <a href='https://www.facebook.com/profile.php?id=61553409478284&mibextid=LQQJ4d' target='_blank' rel='noreferrer'>
                <img className='socials' src={`${process.env.PUBLIC_URL}/icons8-facebook-100.png`} alt='facebook'/>
                </a>
            </div>
            <div className='links'>
                <NavLink className='optionLinks' to='/Items'>My Items</NavLink>
                <NavLink className='optionLinks' to='/Inquire'>Inquire</NavLink>
            </div>
    </div>
  )
}

import React from 'react'
import '../CSS/Footer.css'
import copyright from '../Images/copyright.png'

export const Footer = () => {
  return (
    <div className='footerWrapper'>
        <div className='footerContainer'>
        <img src={copyright} alt='copywright'/>
        <p>HBH 2024</p>
        </div>
    </div>
  )
}